import React from "react"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Hero from "../jobs/components/hero"

const MembersZone = () => {
    
    return (
        <div className="membersZone careersPage">
            <Layout>
                <Seo title="Director, Brand Communications | Careers" />
                <Hero />
                <div className="membersZoneWrapper">
                    <h2>
                       Job Title: Director, Brand Communications
                    </h2>                
                    <p>
                    COMMB is the national not-for-profit organization for the Canadian out-of-home (OOH) industry. Our membership
                    base is comprised of advertisers, agencies, programmatic tech-stacks and OOH companies, large and small. COMMB
                    is responsible for the collective marketing and measurement efforts for the OOH industry, developing proprietary
                    audience measurement methodologies for a variety of OOH media formats and ensuring the voice of OOH is at the
                    forefront of media via broad marketing and communications initiatives.
                    </p>
                    <p>
                    We are seeking someone with leadership experience, willing to join COMMB at a pivotal and transformative time in
                    our organization! Driven by a passion for change, we’re seeking a dynamic person who understands the importance of
                    market presence, knows how to dominate industry communications and leverages strategic brand communications for
                    an increased industry-wide presence.
                    </p>
                    <p>
                    Your position reports directly to the President and you’ll work hand-in-hand with cross-functional teams throughout the
                    organization. You must have prior experience working either agency side or media operator side in the out-of-home
                    advertising industry in a similar role.
                    </p>
                    <p>
                    You’re charismatic, methodical and willing to dive into the industry needs of buyers, sellers and tech-stacks. You’re a
                    self-starter driven by change who thrives in an environment where status-quo does not exist. As a communications
                    expert, your keen ability to understand COMMB’s methodologies/new data and break it down into industry relevant
                    content and marketable materials is critical.
                    </p>
                    <p>
                    If you’re looking for a challenging, yet exciting opportunity to increase COMMB’s voice in the advertising industry,
                    keep reading because we want to hear from you.
                    </p>
                    <p>
                    What's in it for you?
                    </p>
                    <p>
                    <strong>Impact:</strong> COMMB is in the midst of a large product development project which provides new audience measurement
                    methodologies for the Out-of-Home (OOH) and digital Out-of-Home (DOOH) industry. This initiative launches late
                    2022 and will require a great deal of leadership, organization, support and creative marketing communications.
                    </p>
                    <p>                   
                     <strong>Flexibility:</strong> While this is a Toronto-based role, the COMMB team offers work-from-home and scheduling flexibility.
                    </p>
                    <p>
                    <strong>Perks:</strong> We offer a comprehensive compensation and benefits plan, an inclusive & fun office culture, and the freedom
                    to be curious and explore new ideas and solutions.
                    </p>
                    <p>
                    <strong>Challenge:</strong> Your work contributes to the successful launch of COMMB’s new measurement methodologies and
                    product suite. You will be integral in building & creating tactical initiatives necessary to bring COMMB and the OOH
                    industry to the forefront of the advertising community across Canada. You will be a leading partner in COMMB’s
                    public perception, brand image and communications.
                    </p>
                    <p>
                    What we are looking for:
                    </p>
                    <p>
                    You would be a great fit for this opportunity if you have a background in marketing/brand communications within the
                    advertising industry. If this is you, here’s what your day-to-day would look like:
                    </p>
                    <ul>
                    <li>Support day-to-day tasks where needed such as preparing presentations and proofreading</li>
                    <li>Stay abreast of current market trends to ensure COMMB has a voice in key conversations</li>
                    <li>Provide day-to-day tactical support in the development and execution of communications material including
                    website articles, executive messages/posts, general social media posts and speeches, presentations, blogs,
                    videos, and printed materials ensuring information is timely, relevant and accurate</li>
                    <li>Convey key messaging through written communications such as public facing articles, blog posts and other</li>
                    <li>communications outlets</li>
                    <li>Identify and develop opportunities for the inclusion of OOH in the curriculum of advertising and marketing
                    programs at colleges and universities</li>
                    <li>Own and maintain the marketing communications calendar, working with internal departments to ensure
                    alignment of communication needs to areas such as product development, data & insights etc.</li>
                    <li>Share worldwide best practices and capitalize on opportunities globally for the Canadian market; Bring
                    expertise on leading trends in the OOH media space, globally, nationally & locally</li>
                    <li>Work closely with the Marketing & Communications Specialist on all things social & content related including</li>
                    <li>newsletter & website.</li>
                    <li>Create and manage the layout and design of communications that adhere to brand standards</li>
                    </ul>
                    <br />
                    <p>
                    To qualify for this role, we’re seeking:
                    </p>
                    <br />
                    <ul>
                    <ul>
                    <li>5-10 year experience in a mid-level marketing and/or communications role</li>
                    <li>Understanding of the Out-of-Home and digital out-of-home landscape, including programmatic DOOH
                    ecosystems</li>
                    <li>Knowledge in programmatic digital out-of-home or programmatic advertising in general (preferred)</li>
                    <li>A degree in advertising, marketing or an equivalent combination of post-secondary education and relevant
                    work experience</li>
                    <li>Experience working in a media environment, either at an agency or media owner organization</li>
                    <li>A minimum of 3 years in the out-of-home advertising industry</li>
                    <li>Actively involved in marketing and/or communications initiatives within the advertising industry</li>
                    <li>Exceptional public speaking, verbal and written communication skills</li>
                    <li>A deep network of media, publisher, public relations and marketing professionals.</li>
                    <li>Proficiency for influencing decision making, including positive persuasion and negotiation skills are key</li>
                    <li>Ability to adjust and adapt to changes in the workplace while maintaining a positive attitude</li>
                    <li>Extraordinary willingness to learn and apply new skills, adjust effectively to different and changing
                        environments and smoothly integrate new opportunities into your workspace with impeccable written and verbal
                        communication skills with the seamless ability to be persuasive, yet approachable</li>
                    <li>An entrepreneurial spirit willing to take risks to achieve goals when challenged</li>
                    <li>A deep understanding of B2B marketing as it pertains to revenue generation</li>
                    <li>Readiness to become collaborative and accept criticism and new directions</li>
                    <li>Outstanding enthusiasm for being curious about, reflective about, and understanding of people and their</li>
                    <li>values, which are driving factors to aid in your public relations, partnership, and negotiations process</li>
                    </ul>
                    </ul>
                    <p>
                    Interested candidates can forward their resume in confidence to Amanda Dorenberg: <a href="mailto:adorenberg@commb.ca?subject=Applying to the Brand Director Role">adorenberg@commb.ca</a>
                    </p>
                    <p>
                    Job Types: Full-time, Permanent<br />
                    Schedule: Monday to Friday
                    </p>
                    <p>
                    COMMB is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive
                    environment for all employees. Accommodations are available on request for candidates taking part in all aspects of
                    the interview process.
                    </p>

                </div>
            </Layout>
        </div>
    )
}

export default MembersZone